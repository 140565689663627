/* eslint-disable @next/next/no-html-link-for-pages */
//containers
import { Footer } from '@/containers-m/Footer';
import { Navigation } from '/containers-m/';
import { WideCalculatorShipperGuide } from '@/containers-m/WideCalculatorShipperGuide';

// context containers
import {
  Hero,
  BadgeRatings,
  TabsContent,
  PartnersIcons,
  VideoPlayer,
  ComparisonTable,
  Testimonials,
  SideContent,
  SideCTA,
  SideFeatures,
  Form,
  FreightSection,
} from './containers/index';

//styles
import { PageWrapper } from './ShipperGuide.styles';

//assets
import Visibility from '@/public/images/icons/loadsmart-collection/028-sg-tracking-visibility.svg';
import Integration from '@/public/images/icons/loadsmart-collection/022-sg-carrier-scorecards.svg';
import Benchmarking from '@/public/images/icons/loadsmart-collection/035-sg-benchmark-rates.svg';
import Start from '@/public/images/icons/loadsmart-collection/010-sg-manage-carriers.svg';

//assets
import Managed from '@/public/images/icons/loadsmart-collection/013-sg-schedule-a-demo.svg';
import Planning from '@/public/images/icons/loadsmart-collection/002-sg-settle.svg';
import Pay from '@/public/images/icons/loadsmart-collection/031-sg-real-time-carrier-bids.svg';
import Fleet from '@/public/images/icons/loadsmart-collection/024-sg-tender-shipments.svg';

const integration_content = {
  title: (
    <>
      Integrate Your <br /> Supply Chain
    </>
  ),
  description: `Connect with any internal or 3rd party system for enhanced efficiency, better visibility, and improved decision-making across your supply chain.
              `,
  items: [
    {
      icon: <Start width="32" height="32" />,
      title: 'Carrier Integration',
      description:
        'Automate the exchange of data by establishing a connection directly to your carriers via an EDI integration. This enables you to quickly begin tendering, tracking, and invoicing, all within a single, easy-to-use platform.',
    },
    {
      icon: <Integration width="32" height="32" />,
      title: 'ERP and Order Integration',
      description:
        'Send order and shipment information from your ERP directly into ShipperGuide TMS to start scheduling shipments without manually entering shipment details. Shipment updates are also automatically reflected in your ERP.',
    },
    {
      icon: <Visibility width="32" height="32" />,
      title: 'Visibility and Shipment Tracking',
      description:
        'Connect your visibility and shipment tracking software to update location statuses in ShipperGuide in near real-time. Maintain full visibility to your freight without the hassle of moving between different systems.',
    },
    {
      icon: <Benchmarking width="32" height="32" />,
      title: 'Benchmarking Data',
      description:
        'Integrate with data providers, like FreightWaves SONAR, to access market-rate benchmarking directly within ShipperGuide TMS. Use this data to make more informed decisions when selecting shipment coverage to save money on freight.',
    },
  ],
};

const carrier_content = {
  title: (
    <>
      Services to <br />
      Augment <br /> Your TMS
    </>
  ),
  description: (
    <>
      Augment your TMS to suit your company’s specific needs with Loadsmart’s
      expert{' '}
      <a href="/shipper/managed-transportation/">Managed Transportation</a>{' '}
      services. Lean on the experts to craft a bespoke logistics operation based
      on easy-to-use technology that can be implemented in a fraction of the
      time.
    </>
  ),
  items: [
    {
      icon: <Managed width="32" height="32" />,
      title: 'Loadsmart Managed Transportation',
      description:
        'Managed Transportation is a comprehensive solution that features turnkey, end-to-end services for companies that want to outsource all or part of their transportation management process.',
    },
    {
      icon: <Planning width="32" height="32" />,
      title: 'Planning and Optimization as a Service (PaaS & OaaS)',
      description:
        'Leverage the Loadsmart Planning Center of Excellence (COE) to optimize your orders, shipments, and modes, create better load plans, and realize significant savings.',
    },
    {
      icon: <Pay width="32" height="32" />,
      title: 'Freight Pay and Audit',
      description:
        'Audit your carrier invoices against approved rates and let Loadsmart handle factoring and payments so you can rest assured you’re being charged fairly on every shipment.',
    },
    {
      icon: <Fleet width="32" height="32" />,
      title: 'Fleet Optimizer',
      description:
        'Leverage Loadsmart’s Fleet Optimizer to help fill your dedicated fleet’s empty miles with backhauls.',
    },
  ],
};

function ShipperGuide() {
  return (
    <PageWrapper>
      <Navigation theme="dark" />
      <>
        <Hero />

        <TabsContent />
        <FreightSection />
        <SideContent content={integration_content} />
        <WideCalculatorShipperGuide />
        <Form />
        <PartnersIcons />
        <Testimonials />
        <BadgeRatings />
        <VideoPlayer />
        <ComparisonTable />
        <SideContent content={carrier_content} />
        <SideFeatures />
        <SideCTA />
      </>
      <Footer />
    </PageWrapper>
  );
}

export default ShipperGuide;
export { ShipperGuide };
