/* eslint-disable @next/next/no-img-element */
import { useState } from 'react';

// components
import { Container } from 'components/Grid';
import { SectionTitle } from '@/components/SectionTitle';

//vendor
import ReactPlayer from 'react-player/lazy';

// styles
import {
  SectionWrapper,
  LogoWrapper,
  TitleWrapper,
  MediaWrapper,
  VideoWrapper,
  Picture,
  ClosingIcon,
  PlayingIcon,
  Thumbnail,
  ContenCopy,
  CTAWrapper,
  Title,
  Description,
} from './FreightSection.styles';

//icons
import IconPlay from '@/public/images/carrier-tms/video-hero/icon-play.svg';
import { IconClose } from '@loadsmart/icons';
import { MirandaGradientButton } from '@/components/Button';

//logo
import FIAI from '@/public/images/logos/logo-freightintel-ai-black.svg';

export function FreightSection() {
  const [playVideo, setPlayVideo] = useState(false);

  return (
    <SectionWrapper>
      <Container>
        <LogoWrapper>
          <FIAI width="418" height="48" />
        </LogoWrapper>
        <TitleWrapper>
          <Title>Unlocking Insights with advanced machine learning</Title>
          <Description>
            FreightIntel AI acts like a seasoned transportation expert,
            automatically accessing data within your TMS and delivering
            immediate insights at the click of a button.
          </Description>
        </TitleWrapper>

        <MediaWrapper>
          <VideoWrapper className={playVideo ? `active` : ``}>
            <ClosingIcon
              onClick={() => {
                setPlayVideo(false);
              }}
            >
              <IconClose />
            </ClosingIcon>
            <ReactPlayer
              className="react-player"
              url="https://www.youtube.com/watch?v=_rf7_1NE8-M?rel=0"
              controls
              playing={playVideo}
              width="100%"
              height="100%"
            />
          </VideoWrapper>
          <Thumbnail
            className={playVideo ? `inactive` : ``}
            onClick={() => {
              setPlayVideo(true);
            }}
            role="button"
          >
            <Picture>
              <source
                srcSet={`/images/home/freightintel/freightintelai-thumb.jpg`}
                media="(min-width: 1920px)"
              />
              <source
                srcSet={`/images/home/freightintel/freightintelai-thumb.jpg`}
                media="(min-width: 1024px)"
              />
              <source
                srcSet={`/images/home/freightintel/freightintelai-thumb.jpg`}
                media="(min-width: 768px)"
              />
              <img
                srcSet={`/images/home/freightintel/freightintelai-thumb.jpg`}
                alt="Shipper Guide dashboard screen"
                width="677"
                height="520"
              />
            </Picture>
            <PlayingIcon>
              <IconPlay />
            </PlayingIcon>
          </Thumbnail>
        </MediaWrapper>

        <ContenCopy>
          <p>
            By leveraging the logistics data already in ShipperGuide TMS,
            alongside Loadsmart’s trusted benchmarking dataset and cutting-edge
            AI technology, it provides tailored observations and actionable
            recommendations unique to your business. FreightIntel AI harnesses
            advanced machine learning to analyze complex datasets, detect
            patterns, and offer personalized guidance in real time.
          </p>
        </ContenCopy>

        <CTAWrapper>
          <MirandaGradientButton as="a" href="#hubspot-form">
            <span>Request a Demo</span>
          </MirandaGradientButton>
        </CTAWrapper>
      </Container>
    </SectionWrapper>
  );
}
